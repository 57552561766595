import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "../styles/Notification.scss";

interface NotificationData {
  title: string;
  message: string;
  visible: boolean;
  color: string;
}

const defaultNotification: NotificationData = {
  title: "Wichtig:",
  message:
    "Der Parcours ist auf grund von Umbauarbeiten geschlossen und ist am 30.3.2025 wieder geöffnet.",
  visible: true,
  color: "#c62828",
};

export const Notification = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [notificationData, setNotificationData] =
    useState<NotificationData>(defaultNotification);

  useEffect(() => {
    const handleScroll = () => {
      const notifyContainer = document.querySelector(
        ".notify-container"
      ) as HTMLElement;
      if (notifyContainer && window.innerWidth > 768) {
        notifyContainer.style.top = window.pageYOffset > 100 ? "1rem" : "7rem";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchNotification = async () => {
      try {
        const response = await axios.get(
          "https://panroama-bogensport-api.onrender.com:3001/api/notification"
        );
        const data = response.data;

        if (data && Object.keys(data).length > 0) {
          setNotificationData(data);
          setIsVisible(data.visible);
        } else {
          throw new Error("Invalid notification data");
        }
      } catch (error) {
        console.error("Error fetching notification data:", error);
        setNotificationData(defaultNotification);
        setIsVisible(true);
      }
    };

    fetchNotification();
  }, []);

  if (!isVisible) return null;

  return (
    <div
      className="notify-container"
      style={{ backgroundColor: notificationData.color }}
    >
      <div className="notify">
        <div className="notify-content">
          <h1>{notificationData.title}</h1>
          <p>{notificationData.message}</p>
        </div>
        <button
          onClick={() => setIsVisible(false)}
          className="close-btn"
          aria-label="Close notification"
        >
          <FontAwesomeIcon icon={faTimes} color="white" />
        </button>
      </div>
    </div>
  );
};

export default Notification;
